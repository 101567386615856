import React, { useEffect, useState } from "react";
import Navbar from "../../partials/navbar/navbar";
import SideBar from "../../partials/sidebar/sidebar";
import Footer from "../../partials/footer/footer";
import style from "./dashboard.module.css";
import { Card, CardBody } from "../../components/card/card";
import rookie_image from "../../assets/img/jpg/rookie_badge_2.jpg";
import { Link } from "react-router-dom";
import {  useNavigate } from "react-router-dom"; 
import ContentContainer from "../../includes/content_container/contentContainer";
import ProfileCompleteness from "../profile/profile-measure/profile_completeness";
import { useSelector } from "react-redux";
import { AlertDanger } from "../../components/alert/alert";
import ProfilePicture from "../profile/profile-picture/profile_picture";
import { fetchProfileUsingUserId } from "../../services/profile_service";
import { decodeJsonToken } from "../../utils/jwt_utils";



/**
 * This is a Dashboard component it is used to show important information to the Handyman, such as completed profile
 * objectives, Requests for Qoutations, and upcoming bookings.
 * @returns 
 */
const DashBoard = () =>{

    const authToken = useSelector(state => state.auth.token);
    const [serverError,SetServerError] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate()  

    useEffect(() => {
        let decodedToken = decodeJsonToken(authToken)
        fetchProfileUsingUserId(decodedToken.user_id).then((res) => {
            if(res !== null){
                SetServerError(false);
                setErrorMessage('')
            }else{
                SetServerError(true);
                setErrorMessage('Error fetching profile, you will be automatically logged out.');
                navigate('/')
            }
        })
        .catch((err) =>{
            console.error(err)
            SetServerError(true);
            setErrorMessage('Error fetching profile, you will be automatically logged out.');
            navigate('/')
        })
    },[authToken])

    return(
        <>
            <Navbar></Navbar>
            <ContentContainer>
                <SideBar></SideBar>
                <div className={style.main_wrapper}>
                    <div className={style.profile_container}>
                        <Card>  
                            <CardBody>
                                <ProfilePicture />
                                <ProfileCompleteness percentage={5}/>
                            </CardBody>
                        </Card>
                    </div>
                    <div className={style.objectives_container}>
                        { serverError && <AlertDanger message={errorMessage}  />}
                        <h2 className={style.points_header}>Profile Objectives</h2>
                        <h5>Complete these objectives to get rated as a Pro Handyman.</h5>
                        <div className={style.objectives}>
                        <   div className={style.objective_item}>
                                <span className={style.objective_item_title}><i className="bi bi-bullseye"></i> Verify Your Identity</span>
                                <span className={style.objective_status}><Link to="/profile" className={style.btn_start_objective}>Complete Objective  <i className="bi bi-arrow-right"></i></Link></span>
                            </div>
                            <div className={style.objective_item}>
                                <span className={style.objective_item_title}><i className="bi bi-bullseye"></i> Add Skills</span>
                                <span className={style.objective_status}><Link to="/hero-skills" className={style.btn_start_objective}>Complete Objective  <i className="bi bi-arrow-right"></i></Link></span>
                            </div>
                            <div className={style.objective_item}>
                                <span className={style.objective_item_title}><i className="bi bi-bullseye"></i> Add Sample Work</span>
                                <span className={style.objective_status}><Link to={"/hero-completed-missions"} type="button" className={style.btn_start_objective}>Complete Objective  <i className="bi bi-arrow-right"></i></Link></span>
                            </div>
                        </div>
                    </div>
                    <div className={style.ranking_container}>
                        <h4>Your Ranking: Rookie</h4>
                        <Card>
                            <img src={rookie_image} alt="avatar" className={style.rookie_image}/>
                        </Card>
                        <small>Complete the objectives to get promoted to <b>PRO</b> Handyman.</small> 
                    </div>
                </div>
            </ContentContainer>
            <Footer></Footer>
            
        </>
    )

}

export default DashBoard;