import React from "react";
import style from './form.module.css'

const Form = ({ children, variant = "default",onSubmit}) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit();
    }
    return(
        <form onSubmit={handleSubmit} className={style.form}>
            {children}
        </form>
    )
}

export default Form;