import React from "react";
import { useState, useEffect } from "react";
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import style from './my_profile.module.css'
import { useSelector } from 'react-redux';
import { updateUserProfile } from  "../../../services/profile_service"
import Form from "../../../components/form/form";
import TextInput from "../../../components/text-input/text-input";
import { DefaultButtonOutline } from '../../../components/buttons/button';
import PageHeader from "../../../components/page_header/pageHeader";
import toast, { Toaster } from 'react-hot-toast';


/**
 * This component is used to manage the users profile information such as names and contacts.
 * @returns
 */
const MyProfile = () => {

    const userProfile = useSelector(state => state.auth.userProfile);
    const userId = useSelector(state => state.auth.userId);
    const [profileId, SetProfileId] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [homeAddress, setHomeAddress] = useState('');
    const [isLoading,setIsLoading] = useState(false)
    

    const toastError = (message="") => {
        return toast.error(message ? message : "Oops! An error occurred.")
    }


    useEffect(() => {
        // Update component state when userProfile changes
        if (userProfile) {
            SetProfileId(userProfile._id)
            setFirstName(userProfile.firstname || '');
            setLastName(userProfile.lastname || '');
            setEmail(userProfile.email || '');
            setHomeAddress(userProfile.home_address || '');
        }
    }, [userProfile]); 
    

    const handleSubmit = (e) =>{
        console.log('clicked')
        setIsLoading(true)
        const data = {
            user_id: userId,
            firstname: firstName,  
            lastname: lastName,
            email: email,
            home_address: homeAddress
        }

        console.log(data)

       updateUserProfile(profileId, data)
       .then(res => {
            if(res.is_updated){
                toast('Good Job!', {
                    icon: '👏',
                  });
            }
            else{
                toastError("Failed to update information.please try again.")
            }
            setIsLoading(false)
       })
       .catch(err => {
            console.log(err)
            toastError()
            setIsLoading(false)
        })
    }
 

    return(
        <> 
        <PageHeader title={"Contact Info"} 
                tagline={"Additional Contact Information."} />
            <Form onSubmit={handleSubmit}>
                <div className={style.form_container}>
                    <TextInput
                            label="Firstname"
                            name="firstname"
                            value={firstName}
                            onChange={setFirstName}
                        />
                    <TextInput
                            label="Lastname"
                            name="lastname"
                            value={lastName}
                            onChange={setLastName}
                    />
                    <TextInput
                        label="Email"
                        name="email"
                        value={email}
                        onChange={setEmail}
                    />
                    <TextInput
                        label="Home Adddress"
                        name="home_address"
                        value={homeAddress}
                        onChange={setHomeAddress}
                    />
                   

                </div>
                { isLoading ? <LoaderDotted /> : <DefaultButtonOutline>Save</DefaultButtonOutline>}
                
            </Form>
            <Toaster
                position="top-center"
                reverseOrder={true}
            />

        </>
    )
} 

export default MyProfile;