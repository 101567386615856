import React, { useState,useEffect,useRef } from "react";
import style from './file_drag_drop.module.css'


const FileDragDrop = ({FileChange,FileDrop}) =>{


    const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];
    const [fileName, setFileName] = useState("");
    const fileInput = useRef(null);
    const [fileSize, setFileSize] = useState(0);
    const [fileType, setFileType] = useState("");
    const [isFileValid, setIsFileValid] = useState(true) // use to check the state of the file
    


    const onFileChange = (e) => {
        e.preventDefault();
        FileChange(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setFileSize(e.target.files[0].size);
        setFileType(e.target.files[0].type);
        setIsFileValid(true)
    }

    const onHandleFileDrop = (e) => {
        FileDrop(e.dataTransfer.files[0]);
        setFileName(e.dataTransfer.files[0].name);
        setFileSize(e.target.files[0].size);
        setFileType(e.target.files[0].type);
        setIsFileValid(true)
    }

    const preventDefault = (event) => {
        event.preventDefault();
    };

    const fileProperties = {
        fileName,
        fileSize,
        fileType
    }

    const validateFileSize = () =>{
        const allowedFileSize =  fileSize / 1024 / 1024; 
        if(allowedFileSize > 2){
            setIsFileValid(false)
            return false;
        }
        return true;
    }

    const showFileSizeValidationError = !isFileValid ? "File size should be less than 2MB" : ''; 

    return(
        <div style={{ marginBottom:'1rem',border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
            <label
                onDrop={onHandleFileDrop}
                onDragOver={preventDefault}
                htmlFor="fileInput"
                style={{ cursor: 'pointer' }}>
                <p>Drag and drop a image here or click to select</p>
                <input
                    type="file"
                    accept="*"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    id="fileInput"
                />
            </label>
            <p>{fileName}</p>
            <p className={style.text_error}>{showFileSizeValidationError}</p>
        </div>
    )
 }

 export default FileDragDrop;