import { BACKEND_URL } from '../config/api'


export const getVerificationInformation = async (userId="") =>{
    const response = await fetch(`${BACKEND_URL}/api/v1/identity-verification/get-identity-verification-by-user-id/?user_id=${userId}`);
    const data = await response.json();

    if (!response.ok){
        throw new Error(data.message || "Something went wrong");
    }
    return data
    
}

export const getVerificationStatus = async (userId="") =>{
    const response = await fetch(`${BACKEND_URL}/api/v1/identity-verification/get-verification-status/?user_id=${userId}`);
    const data = await response.json();

    if (!response.ok){
        throw new Error(data.message || "Something went wrong");
    }
    return data
    
}


/**
 * Uploads verification information
 * 
 */
export const uploadIdentityDocument = async (userId,formData) => {
    const requestUrl = `${BACKEND_URL}/api/v1/identity-verification/upload-identity-document/?user_id=${userId}`;

    const requestOptions = {
        method: "POST",
        mode: "cors", // Change mode to "cors"
        cache: "no-cache",
        body: formData
    };

    try {
        const response = await fetch(requestUrl, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || "Something went wrong");
        }

        return data;
    } catch (error) {
        console.error("Error during profile image upload:", error);
        throw error; // Re-throw the error to be caught by the caller
    }
};