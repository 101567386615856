import React from "react";
import style from './button.module.css'

export const DefaultButton = ({ children, icon, onClick }) => {
    const classes = style.button + ' ' + style.button_default
    return (
      <button className={classes} onClick={onClick}>
        {icon && <span className="button-icon">{icon}</span>}
        {children}
      </button>
    );
  };


  export const DefaultButtonOutline = ({ children, icon, onClick }) => {
    const classes = style.button + ' ' + style.button_default_outline
    return (
      <button className={classes} onClick={onClick}>
        {icon && <span className="button-icon">{icon}</span>}
        {children}
      </button>
    );
  }; 
  

  
export const InfoButton = ({ children, icon, onClick }) => {
  const classes = style.button + ' ' + style.button_info
  return (
    <button className={classes} onClick={onClick}>
      {icon && <span className="button-icon">{icon}</span>}
      {children}
    </button>
  );
};


export const InfoButtonOutline = ({ children, icon, onClick }) => {
  const classes = style.button + ' ' + style.button_info_outline
  return (
    <button className={classes} onClick={onClick}>
      {icon && <span className="button-icon">{icon}</span>}
      {children}
    </button>
  );
}; 
