import React, { useState,useEffect,useRef } from "react"
import Navbar from "../../../partials/navbar/navbar";
import SideBar from "../../../partials/sidebar/sidebar";
import ContentContainer from "../../../includes/content_container/contentContainer";
import MainContainer from "../../../includes/main_container/mainContainer";
import PageHeader from "../../../components/page_header/pageHeader";
import Footer from "../../../partials/footer/footer";
import style from './completed_work.module.css';
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import { useSelector } from "react-redux";
import FileDragDrop from "../../../components/file_drag_drop/file_drag_drop";
import Form from "../../../components/form/form";
import { DefaultButtonOutline } from "../../../components/buttons/button";
import { removeGalleryImage,uploadGalleryImage } from "../../../services/handyman_service";
import { fetchHandymanProfileUsingUserId } from "../../../services/handyman_service";
import toast, { Toaster } from 'react-hot-toast';

/**
 * This component is used to allow Handyman to upload 3 pictures of their sample work.
 * @returns 
 */
const CompletedWork = () =>{

    const handymanProfile = useSelector(state => state.auth.handymanProfile);
    const baseUrl = 'https://storage.cloud.google.com/homehero_app/'
    const [newImage, setNewImage] = useState(null)
    const [galleryImages, setGalleryImages] = useState([])
    const [isLoading,setIsLoading] = useState(false)
   
    
    const toastSuccess = (message="") => {
        return toast.success(message ? message : "Success")
    }

    const toastError = (message="") => {
        return toast.error(message ? message : "Oops! An error occurred.")
    }
     

    useEffect(() =>{
        if(handymanProfile){
            setGalleryImages(handymanProfile.gallery)
        }
    },[handymanProfile])

    const handleSubmit = () =>{
        setIsLoading(true)
        const formData = new FormData();
        formData.append('image',newImage)
        if(newImage === null){
            setIsLoading(false)
            toastError('Please select an image.')
            return
        }

        uploadGalleryImage(handymanProfile._id, formData).then((res) =>{
            console.log(res.data)
            let responseData = res.data
            if(responseData.is_uploaded){
                toastSuccess("New Image added.")
                setGalleryImages([...galleryImages, res.file_path])
                fetchHandymanProfileUsingUserId(handymanProfile.user_id)
            }else{
                toastError("Failed to upload image.")
            }
            setIsLoading(false)
        }).catch((err) =>{
            console.error(err.message)
            toastError()
            setIsLoading(false)
        })

    }

    const removeImage = (image) =>{
        console.log(image)
        removeGalleryImage(handymanProfile._id,image).then(() =>{
            setGalleryImages(galleryImages.filter((img) => img !== image))
            toastSuccess("Image has been deleted.")
            fetchHandymanProfileUsingUserId(handymanProfile.user_id)
        }).catch((err) =>{
            console.error(err)
            toastError()
        })
    }


    var showWorkCompletedWork = <p style={{color:"red"}}></p>

    if (galleryImages.length > 0){

        showWorkCompletedWork = galleryImages.map((image, index) => {
            const imageUrl = `${baseUrl}${image}`;
            return(
                <div className={style.card_container} key={index}>
                    <span className={style.btn_remove} onClick={() => removeImage(image)}><i className="bi bi-x"></i></span>
                    <img src={imageUrl} alt={image} className={style.placeholder}></img>
                </div>
            )
        })
        
    }

   
    return(
        <>
            <Navbar></Navbar>
            <ContentContainer> 
                <SideBar></SideBar>
                <MainContainer>
                    <PageHeader 
                        title="Completed Work" 
                        tagline="Showcase your best work to attract more clients." />
                    <Form onSubmit={handleSubmit}>
                        <div className={style.upload_container}>
                            <FileDragDrop FileChange={setNewImage} FileDrop={setNewImage} />

                            {isLoading ? <LoaderDotted/> : <DefaultButtonOutline>Upload Image</DefaultButtonOutline> }
                        </div>
                    </Form>
                    <div className={style.samples_container}>
                        {showWorkCompletedWork}
                    </div>
                </MainContainer>
            </ContentContainer>
            <Footer />
            <Toaster
                position="top-center"
                reverseOrder={true}
            />

        </>
    )
}

export default CompletedWork;