import React from "react";
import style from './password_text_input.module.css'

const PasswordTextInput = ({ label="", name="", value, onChange, ...otherProps }) => {
    return (
      <div className={style.password_text_input}>
        <label htmlFor={name}>{label}</label>
        <input
          type="password"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
      </div>
    );
  }
  
  export default PasswordTextInput;