import React from "react";
import { useState } from "react";
import style from './change_password.module.css';
import { InputError } from "../../../../components/input_helper/input_helper_text";
import { LoaderDotted } from "../../../../components/input_helper/loader/loader";
import Form from "../../../../components/form/form";
import PasswordTextInput from "../../../../components/password-text-input/password_text_input";
import { DefaultButtonOutline } from "../../../../components/buttons/button";
import PageHeader from "../../../../components/page_header/pageHeader";


/**
 * This component is used to change the password of the user
 * @returns
 */
const ChangePassword = () =>{   

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false); 


    const handleConfirmPasswordChange = () =>{
        //TODO: Check if the new password and confirm password are the same
        newPassword === confirmPassword ? setShowError(false) : setShowError(true)
    }

    const apiCheckCurrentPassword = async () =>{
        setIsLoading(true)
        setShowLoader(true)
        setShowError(false)
        setShowSuccess(false)
        setMessage('')
        setError(false)

        //TODO1: Send data to server 

        setTimeout(() => {
            setIsLoading(false)
            setShowLoader(false)
            setShowError(false)
            setShowSuccess(false)
            setMessage('')
            setError(false)
        }, 2500)
    };
 
    const handleSubmit = async (e) => {
        console.log('submited');
        e.preventDefault();
        setIsLoading(true);

        if (currentPassword === ''){
            setMessage('Current password is required')
            setError(true)
            setIsLoading(false)
            return
        }

        const data = {
            newPassword: newPassword
        }

        //TODO1: Send new data to server 

        setTimeout(() => {
            setIsLoading(false)
            setShowLoader(false)
            setShowError(false)
            setShowSuccess(false)
            setMessage('')
            setError(false)
        }, 2500)

    }

    return(
       <>
        <PageHeader title={"Password & Security"} 
                tagline={"Secure Your Account."} />
          <Form>
            <PasswordTextInput 
                label="Current Password"
                name="current_password"
                value={currentPassword}
                onChange={setCurrentPassword}
                autoFocus={true}
                autoComplete="off"
            /> 
            <PasswordTextInput
                label="New Password"
                name="new_password"
                value={newPassword}
                onChange={setNewPassword}
                autoFocus={true}
                autoComplete="off"
            />
            <PasswordTextInput
                label="Confirm Password"
                name="confirm_password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                autoFocus={true}
                autoComplete="off"

            />
            <DefaultButtonOutline>
                { isLoading ? <LoaderDotted /> :  "Save" }
            </DefaultButtonOutline>
          </Form>
       </>
    )

}

export default ChangePassword;