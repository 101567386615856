import React from "react";
import style from "./card.module.css"


export const Card = ({ children}) =>{
    return <div className={style.card}> {children} </div>
}


export const CardBody = ({ children}) =>{
    return <div className={style.card_body}>{children} </div>
}

export const CardTitle = ({ children}) =>{
    return <div className={style.card_title}>{children} </div>;
}
