import React from "react";
import style from './profile_completeness.module.css'

const ProfileCompleteness = ({ percentage }) => {

    return (
        <div className={style.profile_completeness}>
        <div className={style.percentage}>{percentage}%</div>
        <div className={style.progress_bar}>
            <div 
            className={style.progress}
            style={{width: `${percentage}%`}}  
            />
        </div>
        </div>
    )
    
}

export default ProfileCompleteness;

