import React from "react";
import style from './notifications.module.css'
import Navbar from "../../partials/navbar/navbar";
import SideBar from "../../partials/sidebar/sidebar";
import ContentContainer from "../../includes/content_container/contentContainer";
import MainContainer from "../../includes/main_container/mainContainer";



/**
 * This is a Notifications component it is used to show the notification page.. 
 * Users will be able to view all system notifications here such as scheduled updates or a change on anythin.
 * @returns 
 */
const NotificationPage = () =>{

    const notificationData =[
        {
            date_published: "12/4/2022",
            message: "Unexpected scheduled maintainnance on the 4th of December, contact support for further enquiries."
        },
        {
            date_published: "12/4/2022",
            message: "Change in privacy policy to take effect on 1st of January 2023."
        },

    ]

    const showNotification = notificationData.map((data, index) =>{
        return(
            <div className={style.notification_card} key={index}>
                <p>{data.date_published}</p>
                <p>{data.message}</p>
            </div>
        )
    }   )

    return(
        <>
            <Navbar></Navbar>
            <ContentContainer>
                <SideBar></SideBar>

                <MainContainer>
                    <div className={style.flex_container}>
                        <h2>Notifications</h2>
                            {showNotification}
                    </div>
                </MainContainer>
            </ContentContainer>
        </>
    )

}

export default NotificationPage; 