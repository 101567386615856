import React from "react";
import { Link } from "react-router-dom";
import style from "./sidebar.module.css";
import { useLocation } from "react-router-dom";


/**
* This component is used to create the Dashboard Sidebar, it should be drawable from the side
@returns
*/
const SideBar = () => {

    

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    
    const sideMenu = [
        {
            title: "Home",
            icon: "bi bi-house",
            path: "/"
        },
        {
            title: "My Profile",
            icon: "bi bi-person-gear",
            path: "/profile"
        },
        {
            title: "Hero Skills",
            icon: "bi bi-tools",
            path: "/hero-skills"
        },
        {
            title: "Work Gallery",
            icon: "bi bi-circle-square",
            path: "/hero-completed-missions"
        },
  
    ]

    const sideMenuItems = sideMenu.map((item, index) => {
        return(
            <li key={index} className={style.sidemenu_item + ' ' + (pathname === item.path ? style.active : '')}>
                <Link to={item.path} className={style.nav_link}>
                    <i className={item.icon + ' ' + (pathname === item.path ? style.icon_active : '')}></i> 
                </Link>
            </li>
            
        )

    })

    return(
        <div className={style.sidebar} id="sidebar">
            <ul className={style.nav_ul}>
               {sideMenuItems} 
            </ul>

        </div>
    )

}

export default SideBar;