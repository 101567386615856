import { jwtDecode } from "jwt-decode";


/**
 *This functionds decodes the json web token for the authenticated user 
 * @returns 
 */
export const parseJsonWebToken = (payload) =>{
    const authPayload = payload
    return authPayload ? authPayload : null
}

export const decodeJsonToken = (jsonWebToken) =>{
    return jsonWebToken ? jwtDecode(jsonWebToken) : null
 }

