import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from './router/router';
import { store, persistor } from './store/store';
import StoreGate from './store/store_gate';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StoreGate>
            <AppRoutes></AppRoutes>
          </StoreGate>
        </PersistGate>
      </Provider>
  </React.StrictMode>
);

