import { BACKEND_URL } from '../config/api'
import { store } from '../store/store'


/**
 * Fetches a users profile using their user id.
 * @returns 
 */
export const fetchProfileUsingUserId = async (userId="") =>{
    const response = await fetch(`${BACKEND_URL}/api/v1/profile/get-profile-by-user-id/?_id=${userId}`);
    const data = await response.json();

    if (!response.ok){
        throw new Error(data.message || "Something went wrong");
    }
    store.dispatch({ type: 'SET_USER_PROFILE', payload: data })
    return data
}
 

/**
 * Updates a Handymans about me text
 * @returns 
 */
export const updateAboutMe = async (profileId="", updateData={}) =>{
    const requestUrl = `${BACKEND_URL}/api/v1/profile/update-about-me/?_id=${profileId}`;

    const requestOptions = {
        method: "PUT",
        mode: "cors", // Change mode to "cors"
        cache: "no-cache",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateData)
    } 

    const response = await fetch(requestUrl, requestOptions);

    const data = await response.json();
        
    if (!response.ok){    
        throw new Error(data.message || "Oops!, Something went wrong.");
    }

    return data
} 

/**
 * Fetches a users profile
 * @returns 
 */
export const updateUserProfile = async (profileId="", profileData={}) =>{
    const requestUrl = `${BACKEND_URL}/api/v1/profile/update/?_id=${profileId}`;

    const requestOptions = {
        method: "PUT",
        mode: "cors", // Change mode to "cors"
        cache: "no-cache",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(profileData)
    } 

    const response = await fetch(requestUrl, requestOptions);

    const data = await response.json();
        
    if (!response.ok){    
        throw new Error(data.message || "Something went wrong");
    }

    return data
} 



/**
 * Uploads a Users profile image
 * 
 */
export const uploadProfileImage = async (profileId,formData) => {
    const requestUrl = `${BACKEND_URL}/api/v1/profile/upload-profile-image/?profile_id=${profileId}`;
    console.log(requestUrl)
    const requestOptions = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        mode: "cors", // Change mode to "cors"
        cache: "no-cache",
        body: formData
    };

    try {
        const response = await fetch(requestUrl, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || "Something went wrong");
        }

        return data;
    } catch (error) {
        console.error("Error during profile image upload:", error);
        throw error; // Re-throw the error to be caught by the caller
    }
};
