import React, { useState,useEffect,useRef } from "react"
import { fetchSkills } from "../../../../services/skills_service";
import style from './searchbar.module.css'

const SearchBar = ({onSelectedOption}) => {

    const [searchText, setSearchText] = useState("");
    const [skills,setSkills] = useState([]);
    const [filteredSkills,setFilteredSkills] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const inputRef = useRef(null);

    useEffect(() =>{
        setIsLoading(true)
        const getSkills = async () => {
            await fetchSkills().then((res) =>{
                setSkills(res)
                setIsLoading(false)
            }).catch((err) =>{
                console.error(err)
                setIsLoading(false)
            })
        }
        getSkills()
    },[])

    const handleInputChange = (event) =>{
        let skillText = event.target.value;
        setSearchText(skillText)
        if(skills !== undefined && skills.length > 0){
            setFilteredSkills(skills.filter((skill) =>{
                return skill.name.toLowerCase().includes(searchText.toLowerCase());
            }))

        }
        
    }
    const selectedSkill = (_id,name) =>{
        let value = {
            _id,
            name
        }
        onSelectedOption(value)
        setSearchText("")
        setFilteredSkills([])
    }

    return(
        <div className={style.search_skills_container}>
            <input ref={inputRef} 
                className={style.search_bar} 
                type="text"
                value={searchText}
                onChange={handleInputChange}
                placeholder="plumber"
            />
            {isLoading && <small className={style.feedback_text}>Loading...</small>}
            <div className={style.search_options}>
                {
                    skills.length === 0 && <div className={style.option_item}>No data available</div>
                }
                {filteredSkills.length !== undefined && filteredSkills.length > 0 && filteredSkills.map((skill) =>{
                    return <div key={skill._id} className={style.option_item} onClick={() => selectedSkill(skill._id,skill.name)}>{skill.name}</div>
                })}
            </div>
        </div>
    )
}

export default SearchBar;