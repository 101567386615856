import React from "react";
import { useState ,useRef, useEffect} from 'react';
import style from './profile_picture.module.css'
import avatar_placeholder from "../../../assets/img/jpg/avatar-placeholder.jpg";
import { uploadProfileImage, fetchProfileUsingUserId } from "../../../services/profile_service";
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import { useSelector } from "react-redux";



/**
 * This component is a dialog window to upload their profile picture
 */
const ProfilePictureDialog =({openModal, closeModal}) =>{

    const userProfile = useSelector(state => state.auth.userProfile);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [fileSizeError, setFileSizeError] = useState(false)
    const hiddenFileInput = useRef(null);
    const modalRef = useRef(null);
    const maxFileSize = 2 * 1024 * 1024; // 2mb 


    useEffect(() => {
        if (openModal) {
            modalRef.current?.showModal();
          } else {
            modalRef.current?.close();
          }
    } ,[openModal])

    const triggerCloseModal = () =>{
        setSelectedFile(  null)
        hiddenFileInput.current.value = null;
        modalRef.current?.close();
        setPreviewImage(null)
        closeModal()
    }

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
            setFileSizeError(false);
            setError(false);
            setPreviewImage(URL.createObjectURL(file)); // Pass the file object to createObjectURL
        }
       
    };

   
    const handleFileClick = (event) => {
        setFileSizeError(false)
        hiddenFileInput.current.click();        
    };

    const handleFileUpload = (event) => {
        setIsLoading(true)

        if(selectedFile.size > maxFileSize){
            setFileSizeError(true)
            setIsLoading(false)
            return
        }

        const formData = new FormData();
        formData.append('profile_image', selectedFile)


        uploadProfileImage(userProfile._id,formData).then(res =>{
            var isUploaded = res.is_uploaded;
            //Update the redux store here
            if(isUploaded){
                fetchProfileUsingUserId(userProfile.user_id).then(res =>{
                    triggerCloseModal()
                })
                setFileSizeError(false)
                setIsLoading(false)
            }
        }).catch((err) =>{
            console.error(err)
            setError(true)
            setIsLoading(false)
        })
        
    };
    
    const showUploadButton = isLoading ? <LoaderDotted></LoaderDotted> :  <button className={style.btn_upload_image} onClick={handleFileUpload}><i className="bi bi-upload"></i>Upload</button>

    return(
        <dialog ref={modalRef} id="modal" className={style.modal} open> 
            <div className={style.upload_modal_container}>
                { fileSizeError && <div className={style.upload_modal_error}><i className="bi bi-exclamation-circle"></i> File is too large, it should be less than 2MB</div> }
                { error && <div className={style.upload_modal_error}><i className="bi bi-exclamation-circle"></i> Error uploading profile picture</div> }
                <h4>Upload your profile picture <button className={style.btn_close_dialog} onClick={triggerCloseModal} autoFocus><i className="bi bi-x"></i></button></h4>
                <img src={ previewImage !== null ? previewImage : avatar_placeholder} 
                    alt="avatar" 
                    className={style.preview_image}
                    accept="image/*"
                    onClick={handleFileClick}
                />
                <div className={style.upload_modal_button_container}>
                    <input className={style.btn_choose_image} type="file" ref={hiddenFileInput} onChange={handleFileChange} />
                   {
                        selectedFile &&  showUploadButton
                   }
                </div>
            </div>        
        </dialog>
    );

}

export default ProfilePictureDialog;