

const initialState = {
    token: null,
    isAuthenticated: false,
    userId: null,
    profile: null,
    handymanProfile: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                token: action.payload,
                isAuthenticated: true,
            };
        case 'LOGOUT':
            return {
                ...state,
                token: null,
                isAuthenticated: false,
            };
        case 'SET_USER_ID':
                return { ...state, userId: action.payload };
        case 'SET_USER_PROFILE':
             return { ...state, userProfile: action.payload };
        case 'SET_HANDYMAN_PROFILE':
                return { ...state, handymanProfile: action.payload };
        default:
            return state;
    }
};

export default authReducer;