import React, { useState,useEffect } from "react"
import Navbar from "../../../partials/navbar/navbar";
import SideBar from "../../../partials/sidebar/sidebar";
import style from './select_skills.module.css';
import SearchBar from "./search/searchbar";
import ContentContainer from "../../../includes/content_container/contentContainer";
import MainContainer from "../../../includes/main_container/mainContainer";
import PageHeader from "../../../components/page_header/pageHeader";
import { useSelector } from "react-redux";
import  { addHandymanSkill, removeHandymanSkill, fetchHandymanProfileUsingUserId } from "../../../services/handyman_service";
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import toast, { Toaster } from 'react-hot-toast';

/***
 * This component is used to allow Handyman to select the kind of services/skills they offer.
 * @returns  {JSX.Element}
 */

const SelectSkillsPage = () =>{

    const handymanProfile = useSelector(state => state.auth.handymanProfile);
    const [selectedSkills,setSelectedSkills] = useState([]);
    const [isLoading,setIsLoading] = useState(false)

    const toastSuccess = (message="") => {
        return toast.success(message ? message : "Success")
    }

    const toastError = (message="") => {
        return toast.error(message ? message : "Oops! An error occurred.")
    }

    useEffect(() =>{
        if(handymanProfile){
            setSelectedSkills(handymanProfile.skills)
        }
        //setErrorMessage('You need to login to access this page')
    },[handymanProfile])

    const handleRemoveSkill = (skill_id) =>{
            setIsLoading(true)
            removeHandymanSkill(handymanProfile._id, skill_id).then((response) =>{
                if(response.is_updated){
                    setSelectedSkills(selectedSkills.filter((skill) => skill._id !== skill_id))
                    
                    fetchHandymanProfileUsingUserId(handymanProfile.user_id)
                    toastSuccess("Your skills data is updated.")
                    
                }else{
                    toastError("Failed to update data, please try again.")
                }
            setIsLoading(false)
                
                
            }).catch((err) =>{
                console.error(err)
                toastError()
                setIsLoading(false)
              
            })
       
    }

    const handleAddNewSkill = (skill) =>{
            setIsLoading(true)
            addHandymanSkill(handymanProfile._id, skill).then((response) =>{
                if(response.is_updated){
                    toastSuccess("New skill added.")
                    fetchHandymanProfileUsingUserId(handymanProfile.user_id)
                
                }else{
                    toastError("Failed to update data, please try again.")
                }
                setIsLoading(false)
            }).catch((err) =>{
                toastError()
                setIsLoading(false)

            })
    }

    var showSkills = <p style={{color:"red"}}>You have not selected any skills</p>

    if (selectedSkills.length > 0 && selectedSkills.length <= 3){
        showSkills = selectedSkills.map((skill,index) =>{
            return(
                <div key={index} className={style.skill_card}>
                    <span className={style.skill_remove_icon} onClick={() => handleRemoveSkill(skill._id)}><i className="bi bi-x"></i></span>
                    <span className={style.skill_card_icon}><i className="bi bi-wrench"></i></span>
                    <h6 className={style.skill_card_header}>{skill.name}</h6>
                </div>
            )
        });
    }

    const handleCheckItemInMySkills = (name) =>{
        return selectedSkills.includes(name)
    }

    const onSelectedOption = (skillObj) =>{
       
        if (selectedSkills.length < 3 && !handleCheckItemInMySkills(skillObj.name)){
            handleAddNewSkill(skillObj)
            setSelectedSkills([...selectedSkills, {id:skillObj._id,name: skillObj.name}])
        }
    }   

    return(
        <>
            <Navbar></Navbar>
            <ContentContainer>
                <SideBar></SideBar>
                <MainContainer>
                  <PageHeader title={"Select Your Skills"} tagline={"Indicate what you do best."} />
                   <SearchBar onSelectedOption={onSelectedOption}></SearchBar>
                   { isLoading && <LoaderDotted></LoaderDotted>}
                    <div className={style.selected_handyman_skills}>
                        {showSkills}
                    </div>
                </MainContainer>
            </ContentContainer>
        </>
    )

}

export default SelectSkillsPage;