import React, { useState } from "react";
import style from "./login.module.css";
import { Link } from "react-router-dom";
import { InputError } from "../../components/input_helper/input_helper_text";
import {  useNavigate } from "react-router-dom";  
import { LoaderDotted } from "../../components/input_helper/loader/loader";
import { login } from "../../services/auth_service";
import { AlertDanger } from "../../components/alert/alert";
import { fetchProfileUsingUserId } from "../../services/profile_service";
import { fetchHandymanProfileUsingUserId } from "../../services/handyman_service";
import { decodeJsonToken } from "../../utils/jwt_utils";
 



/**
 * This is a LoginPage component it is used to show the login page. Handyman can login using their mobile number and password. 
 * It is the main entry page component to the application. 
 * @returns 
 */
const LoginPage = () =>{

    const [cellphone,setCellPhone] = useState('')
    const [password, setPassword] = useState('')
    const [cellphoneError, setCellphoneError] = useState(false)
    const [passwordError, setPasswordError] = useState(false) 
    const [isLoading,setIsLoading] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const navigate = useNavigate()  
   

    const handleCellphoneChange = (e) => {
        const value = e.target.value;
        setCellPhone(value);
        setCellphoneError(false)
        setLoginError(false)
    }
 
    const handlePasswordChange = (e) => {
        const value = e.target.value
        setPassword(value);
        setPasswordError(false)
        setLoginError(false)
    } 

    const handleSubmit = async(e) => {
        e.preventDefault()
        setIsLoading(true)
        //validate cellphone number
        const botswanaPhoneNumberPattern = /^7\d{7}$/;
        if(!cellphone.match(botswanaPhoneNumberPattern)){
            setCellphoneError(true)
            setIsLoading(false)
            return
        }

        //validate password, check for null
        if(password.length === 0){
            setPasswordError(true)
            setIsLoading(false)
            return 
        
        }

        try {   

            const authData = {
                cellphone: cellphone,
                password: password
            } 
    
            login(authData).then(async(tokenResponse) => {    
                let decodedToken = decodeJsonToken(tokenResponse)
                console.log(decodedToken.user_id)
    
                fetchProfileUsingUserId(decodedToken.user_id).then((res) =>{ 
                    setIsLoading(false)
                    navigate('/')
                    fetchHandymanProfileUsingUserId(decodedToken.user_id)

                }).catch(err => {
                    setLoginError(true)
                    setIsLoading(false)
                    console.error(err)
                    navigate('/login')
                    return
                })
            }).catch(err => {
                setLoginError(true)
                setIsLoading(false)
                console.error(err)
                navigate('/login')
            }) 
            
        } catch (error) {
            setLoginError(true)
            setIsLoading(false)
            navigate('/login')
            return 
            
        }
    }

    return(
        <div className={style.container}>
            <div className={style.row}>
            <div className={style.card}>
                <div className={style.card_header}>
                    <span className={style.card_icon}><i className="bi bi-person"></i></span>
                    <p>Use your mobile cellphone number and password to login</p>
                    { loginError && <AlertDanger  message="cellphone or password incorrect please try again."/>}
                </div>
                <div className={style.card_body}>
                    <form onSubmit={handleSubmit}>
                        <div className={style.form_group}>
                            <label className={style.label} htmlFor="cellphone" aria-description="Cellphone">Cellphone <small>(+267)</small></label>
                            <input type="text" 
                                value={cellphone} 
                                autoComplete="off" 
                                autoFocus={true} 
                                id="cellphone" 
                                onChange={handleCellphoneChange} 
                                className={style.form_control} 
                                placeholder="71 000 000"
                                required
                                />
                            { cellphoneError && <InputError message="This service is only available for Botswana phone numbers. 🥲" />  }
                        </div>
                        <div className={style.form_group}>
                            <label className={style.label} htmlFor="password"  aria-description="Password">Password</label>
                            <input type="password" 
                                value={password} 
                                autoComplete="off" 
                                autoFocus={true} 
                                id="password" 
                                onChange={handlePasswordChange} 
                                className={style.form_control} 
                                placeholder="Enter your password"
                                required />
                            { passwordError && <InputError message="Must be a valid password" />  }
                        </div>
                        <div className={"text_align_center " + style.form_group}>
                          { isLoading ? <LoaderDotted /> :  <button type="submit" className={style.btn_login}>Login</button> } 
                        </div>
                        <Link to="/forgot-password" className={style.link_forgot_password}>Forgot password?</Link>
                    </form>
                </div>
            </div>

            </div>
          
        </div>
    )

}

export default LoginPage;