import React from 'react';
import Navbar from '../../partials/navbar/navbar';
import SideBar from '../../partials/sidebar/sidebar';
import style from './edit_profile.module.css';
import MyProfile from './my-profile/my_profile';
import AboutMe from './about-me/about_me';
import ChangePassword from './security/change-password/change_password';
import DeleteAccount from './delete-account/delete_account';
import AccountVerification from './kyc/account_verification';
import ContentContainer from '../../includes/content_container/contentContainer';
import MainContainer from '../../includes/main_container/mainContainer';
import Footer from '../../partials/footer/footer';


/**
* This component is used to edit the profile of the user, it is a page
*/
const EditProfile = () => { 


    const [currentTab, setCurrentTab] = React.useState(0);

    const handleTabClick = (index) => {
        setCurrentTab(index);
    };

    const tabHeading = [
        {
            title: "About Me",
            icon: "bi-person-lines-fill",
        },
        {
            title: "Contact info",
            icon: "bi-person-rolodex",
        },
        {
            title: "Password & Security",
            icon: "bi bi-lock",
        },
        {
            title: "Identity Verification",
            icon: "bi bi-fingerprint",
        },
        {
            title: "Delete Account",
            icon: "bi bi-trash",
        }
    ];

    const showTabHeading = tabHeading.map((heading, index) => {
        return(
            <div className={style.tab_heading + ' ' + (index === currentTab ? style.active_tab : ' ')} key={index} onClick={() => handleTabClick(index)}>
                <span className={style.icon}><i className={heading.icon}></i></span>
                <h5 className={style.heading_title}>{heading.title}</h5> 
            </div>
        )
    });

    const toggleBetweenTabs = () => {
        switch(currentTab){
            case 0 :
                return <AboutMe></AboutMe>
            case 1:
                return <MyProfile></MyProfile>
            case 2:
                return <ChangePassword></ChangePassword>
            case 3:
                return <AccountVerification></AccountVerification>
            case 4:
                return <DeleteAccount></DeleteAccount>
            default:
                return <AboutMe></AboutMe>

        }
    }
   
    return(
        <>
            <Navbar></Navbar>
            <ContentContainer>
                <SideBar></SideBar>
                <MainContainer>
                    <div className={style.tab_wrapper}>
                        <div className={style.tab_header}>
                            {showTabHeading}
                        </div>
                        <div className={style.tab_content}>
                            {toggleBetweenTabs()}
                        </div>
                    </div>
                </MainContainer>
            </ContentContainer>
            <Footer />
        </>
    )

}

export default EditProfile;