import React from "react";
import style from './text-input.module.css'

function TextInput({ label="", name="", value, onChange, ...otherProps }) {


  const handleOnChange = (event) =>{
    let value = event.target.value;
    onChange(value);

  
  }

    return (
      <div className={style.text_input}>
        <label htmlFor={name}>{label}</label>
        <input
          type="text"
          id={name}
          name={name}
          value={value}
          onChange={handleOnChange}
          {...otherProps}
        />
      </div>
    );
  }
  
  export default TextInput;