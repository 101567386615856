import React, { useState } from "react";
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import style from './delete_account.module.css';
import { AlertSuccess } from "../../../components/alert/alert";


/**
 * This component will send a request to the server to delete the account.
 */
const DeleteAccount = () =>{

    const [isLoading,SetIsLoading] = useState(false)

    /**
     * This function will send a request to the server to delete the account.
     */
    const apiDeleteAccount = async () =>{
        SetIsLoading(true);
        console.log("clicked");

        setTimeout(() => {
            SetIsLoading(false);
        },1500)

    }

    return(
        <div className={style.wrapper}>
            <h4>Your account will be deleted after 30 days, if you have any questions please send an email to info@homehero.co.bw</h4>
            { isLoading ? <LoaderDotted /> : <button className={style.btn_delete_account} type="button" onClick={apiDeleteAccount}>Delete Account</button>  }
           
        </div>
    )

}

export default DeleteAccount;