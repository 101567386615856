import React from "react";
import style from './account_verification.module.css' ;


/**
 * This component shows when a user(s) account is approved.
 * @returns  {JSX.Element}
 */
const VerificationComplete= () =>{

    return(
        <div className={style.verification_card}>
            <span className={style.check_icon}><i className="bi bi-check-circle-fill"></i></span>
            <h3>Verification Complete</h3>
            <p>Congratulations! Your identity has been successfully verified.</p>
        </div>
    )

}


export default VerificationComplete