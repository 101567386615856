import React from "react";
import style from './account_verification.module.css' ;
import ProgressBar from "../../../components/progress_bar/progress_bar";
import { InfoButtonOutline } from "../../../components/buttons/button";


/**
 * This component shows when a user(s) account is going through the verification process
 * @returns  {JSX.Element}
 */
const PendingVerification = () =>{

    return(
        <div className={style.pending_verification_card}>
            <h3>Currently Processing Your Identity</h3>
            <ProgressBar percentage={50} />
           <p>We have recieved your information and we are currently verifying your identity. Please wait while we process your information.</p>
           <InfoButtonOutline>Learn More</InfoButtonOutline>
        </div>
    )

}


export default PendingVerification