import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import Form from "../../../components/form/form";
import TextAreaInput from "../../../components/text-area-input/text_area";
import { DefaultButtonOutline } from "../../../components/buttons/button";
import PageHeader from "../../../components/page_header/pageHeader";
import { updateAboutMe } from "../../../services/profile_service";
import toast, { Toaster } from 'react-hot-toast';

/**
This component is used to update an about me by the Handyman
@returns {JSX.Element}
**/
const AboutMe = () => {

  const userProfile = useSelector(state => state.auth.userProfile);
  const [aboutMeText, setAboutMeText] = useState('');
  const [wordCount, SetWordCount] = useState(0)
  const maxCharacters = 250
  const [isLoading,setIsLoading] = useState(false);
  const [editor,setToggleEditor] = useState(false); //TODO : Feature to toggle between edit and view

  const toastError = (message="") => {
      return toast.error(message ? message : "Oops! An error occurred.")
  }
  
  useEffect(() => {
      if(userProfile){
          setAboutMeText(userProfile.about_me || '')
      }
  },[userProfile])

  const handleSubmit = () =>{
      setIsLoading(true)
  
      const data = {
        about_me: aboutMeText
      } 
  
     updateAboutMe(userProfile._id,data).then((res) =>{
        if(res.is_updated){
          toast('Saved, Good Job!', {
            icon: '👏',
          });
        }else{
          toastError("Failed to update information.please try again.")
        }
        setIsLoading(false)
      }).catch((err) =>{
        console.log(err)
        toastError()
        setIsLoading(false)

      })
    
  }

  const showLoaderOnClick = () =>{
      switch(isLoading){
          case true:
              return <LoaderDotted />
          default:
              return <DefaultButtonOutline>Save</DefaultButtonOutline>
      }
  }

  return (
    <> 
      <PageHeader title={"Brand YourSelf"} 
                tagline={"Tell us a bit yourself and your experience helping others."} />
        <Form onSubmit={handleSubmit}>
          <TextAreaInput
            name={"aboutMe"}
            onChange={setAboutMeText}
            value={aboutMeText}
          
          />
           {showLoaderOnClick()}
        </Form>
        <Toaster
                position="top-center"
                reverseOrder={true}
            />

        
    </>
  )
}

export default AboutMe;