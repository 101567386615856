import React from "react"
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom"
import LoginPage from "../pages/login/login";
import DashBoard from "../pages/dashboard/dashboard";
import EditProfile from "../pages/profile/edit_profile";
import Projects from "../pages/projects/projects";
import NotificationPage from "../pages/notifications/notifications";
import ProfessionalCertificationsPage from "../pages/profile/professional_certifications/professional_certifications";
import SelectSkillsPage from "../pages/profile/select-skills/select_skills";
import CompletedWork from "../pages/profile/completed-work/completed_work";
import { useSelector } from "react-redux";
import { ErrorPageNotFound } from "../pages/errors/errorPageNotFound";
import { useEffect, useState } from "react";
import AccountVerification from "../pages/profile/kyc/account_verification";


const AppRoutes = () => { 

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    var authState = useSelector(state => state.auth.isAuthenticated);

    useEffect(() => {
        setIsAuthenticated(authState)
    },[authState])

    const private_routes = [
        {
            path: '/',
            element:<Outlet></Outlet>,
            errorElement: <ErrorPageNotFound></ErrorPageNotFound>,
            children:[
                {
                    path: "/",
                    element: <DashBoard></DashBoard>,
                    errorElement: <LoginPage></LoginPage>,
                },
               
                {
                    path: "/profile",
                    element: <EditProfile></EditProfile>,
                },
                {
                    path: "/projects",
                    element: <Projects></Projects>,
                },
                {
                    path: "/system-notifications",
                    element: <NotificationPage></NotificationPage>,
                },
                {
                    path: '/verify-identity',
                    element: <AccountVerification></AccountVerification>
                },
                {
                    path: "/upload-professional-certifications",
                    element: <ProfessionalCertificationsPage></ProfessionalCertificationsPage>
                },
                {
                    path: "/hero-skills",
                    element: <SelectSkillsPage></SelectSkillsPage>
                },
                {
                    path: "/hero-completed-missions",
                    element: <CompletedWork />
                },
    
                
            ]
        }
        
    ]


    const public_routes = [
        //We define the public routes like the login page outside of the PrivateRoute component.
        {
            path: "/",
            element: <LoginPage></LoginPage>,
            errorElement: <LoginPage></LoginPage>,
        },
    ]

    const router = createBrowserRouter([
        ...(isAuthenticated ? private_routes : public_routes),
    ])

    return <RouterProvider router={router}></RouterProvider>

}

export default AppRoutes;