import React from "react"
import { useState } from "react"
import style from './alert.module.css'



export const AlertInfo = ({message, isVisible=false}) =>{


    return(
        <div className={style.alert_info}>
            <span><span className={style.alert_icon}><i className='bi bi-exclamation'></i></span>
            { message }</span>
        </div>
        
    )
}


export const AlertSuccess = ({message}) =>{


    return(
        <div className={style.alert_success}>
            <span><span className={style.alert_icon}><i className='bi bi-check-lg'></i></span>
            { message }</span>
        </div>
        
    )
}

export const AlertWarning = ({message}) =>{

    return(
        <div className={style.alert_warning}>
            <span><span className={style.alert_icon}><i className='bi bi-exclamation'></i></span>
            { message }</span>
            
        </div>
        
    )
}

export const AlertDanger = ({message}) =>{

    return(
        <div className={style.alert_danger}>
            <span><span className={style.alert_icon}><i className='bi bi-exclamation'></i></span>
            { message }</span>
        </div>
        
    )
}