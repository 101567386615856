import React from "react";
import style from './footer.module.css'

const Footer = () => {
    const today = Date.now()
    const year = new Date(today).getFullYear()
    return (
        <footer className={style.footer}>
            <p>© {year} - Acumetrics All rights reserved</p>
        </footer>
    )

}

export default Footer;