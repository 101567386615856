import { BACKEND_URL } from '../config/api'


/**
 * Fetches all skills
 * @returns 
 */
export const fetchSkills = async () =>{
    const response = await fetch(`${BACKEND_URL}/api/v1/skill/`);
    const data = await response.json();
        

    if (!response.ok){
        throw new Error(data.message || "Something went wrong");
    }
    
    return data;
}


