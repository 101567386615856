import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import style from './navbar.module.css'
import img_placeholder from '../../assets/img/webp/placeholder-image.webp'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';



const Navbar = () => {
    const baseUrl = 'https://storage.cloud.google.com/homehero_app/'

    const [displayFirstname, setDisplayFirstname] = useState('')
    const [displayLastname, setDisplayLastname] = useState('')
    const [avatarUrl, setAvatarUrl] = useState(null)
    const [toggleProfileDropDown, setToggleProfileDropDown] = useState(false)
    const [isMobileMenuOpen,setIsMobileMenuOpen] = useState(false)
    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.auth.userProfile);

    const mobileMenu = [
        {
            title: "Home",
            icon: "bi bi-house",
            path: "/"
        },
        {
            title: "My Profile",
            icon: "bi bi-person-gear",
            path: "/profile"
        },
        {
            title: "Hero Skills",
            icon: "bi bi-tools",
            path: "/hero-skills" 
        },
        {
            title: "Work Gallery",
            icon: "bi bi-circle-square",
            path: "/hero-completed-missions"
        },
  
    ]

    useEffect(() => {
        if(userProfile.avatar){
            var imagePath = baseUrl + userProfile.avatar;
            setAvatarUrl(imagePath)
            setDisplayFirstname(userProfile.firstname || '')
            setDisplayLastname(userProfile.lastname || '')
        }
    }, [userProfile])


    const handleLogout = () =>{
        dispatch({ type: 'LOGOUT' })
    } 

    const mobileMenuItems = mobileMenu.map((item, index) => {
        return(
            <li key={index} className={style.mobile_menu_nav_item}>
                <Link to={item.path} className={style.nav_link}>
                    <i className={item.icon}></i>
                </Link>
            </li>
            
        )

    })
  
    /**
     * This component is used to create the Dashboard Navbar
     */
    return(
        <nav className={style.navbar + " " + style.fixed_top + " " + style.display_flex }>
            {/**
             *  <div className={style.brand_wrapper}>
                <Link to="/" className={style.brand_name}>
                    HomeHero
                </Link>
            </div>
             */}
           
            <div className={style.menu_wrapper + " " + style.align_items_stretch}>
                <ul className={style.nav_right}>
                    <li className={style.nav_item + " " + style.nav_profile_dropdown}>
                        <div className={style.nav_profile_dropdown_toggle} onClick={() => setToggleProfileDropDown(!toggleProfileDropDown)}>
                            <div className={style.profile_img_container}>
                                <img className={style.img_profile} src={avatarUrl !== null ? avatarUrl : img_placeholder} alt={displayFirstname + "profile"} />
                            </div>
                            <div className={style.profile_text_container}>
                                <span className={style.nav_profile_dropdown_name}>
                                    { displayFirstname } { displayLastname}
                                </span>
                               { toggleProfileDropDown ?  <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i> }
                               {   toggleProfileDropDown ?
                                    <div className={style.nav_profile_dropdown_menu}>
                                            <ul>
                                                <li><Link to={"/profile"} className={style.nav_link}> Your Profile</Link></li>
                                                <li>Contact Support</li>
                                                <li onClick={handleLogout}>Logout</li>
                                            </ul>
                                    </div>
                                : <div></div>
                            }  
                            </div>
                        </div>
                    </li>
                    <li className={style.nav_item}>
                        <Link to={"/system-notifications"} className={style.btn_icon}><i className='bi bi-bell'></i></Link>
                    </li>
                    <li className={style.nav_item}>
                        <button type='button' onClick={handleLogout} className={style.btn_icon}><i className='bi bi-box-arrow-right'></i></button>
                    </li>
                </ul>
            </div>
            <div className={style.mobile_navbar}>
                <span className={style.notification_icon}>
                    <Link to={"/system-notifications"} className={style.btn_icon}><i className='bi bi-bell'></i></Link>
                </span>
                <span className={style.toggle_menu_icon} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    { isMobileMenuOpen ? <i className='bi bi-chevron-up'></i> : <i className='bi bi-chevron-down'></i> }
                </span>
                { isMobileMenuOpen ? 
                    <div className={style.mobile_menu}>
                        <ul className={style.mobile_menu_ul}>
                            {mobileMenuItems}
                            <li className={style.mobile_menu_nav_item}>
                                <button type='button' onClick={handleLogout} className={style.btn_icon}><i className='bi bi-box-arrow-right'></i></button>
                            </li>
                         
                        </ul>
                    </div>
                    : <div></div>
                }
               
            </div>
        </nav>
    )

}

export default Navbar;