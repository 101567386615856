import React from "react";
import style from './text_area.module.css'

const  TextAreaInput =  ({ label, name, value, onChange, rows = 5,...otherProps }) => {

  const handleOnChange = (event) =>{
    let value = event.target.value;
    onChange(value);

  
  }
    return (
      <div className={style.text_area}>
        <label htmlFor={name}>{label}</label>
        <textarea
          id={name}
          name={name}
          onChange={handleOnChange}
          rows={rows}
          {...otherProps}
          defaultValue={value}
        >
        </textarea>
      </div>
    );
  }
  
  export default TextAreaInput;