import React from 'react';
import { useState, useEffect } from 'react';
import style from './profile_picture.module.css';
import avatar_placeholder from "../../../assets/img/jpg/avatar-placeholder.jpg";
import ProfilePictureDialog from "./upload_modal";
import { useSelector } from 'react-redux';



/**
 * This is component allows the user to update their profile picture
 * @returns 
 */
const ProfilePicture = () => {

    const userProfile = useSelector(state => state.auth.userProfile);
    const baseUrl = 'https://storage.cloud.google.com/homehero_app/'
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {   
        if(userProfile.hasOwnProperty('avatar')){
            var imagePath = baseUrl + userProfile.avatar;
            setAvatarUrl(imagePath);
        }
    }, [userProfile])
 

    return(
        
        <div className={style.profile_image_container}>
        {
            avatarUrl !== null ? 
            <img src={avatarUrl} 
                alt="avatar" 
                className={style.profile_img}
            />
            :
            <img src={avatar_placeholder} 
                alt="avatar" 
                className={style.profile_img}
            />
        }
        
        <button className={style.btn_pick_image} type='button' onClick={() => setIsDialogOpen(true)} >
            <i className='bi bi-camera'></i>
        </button>
        <ProfilePictureDialog openModal={isDialogOpen} closeModal={() => setIsDialogOpen(false)}></ProfilePictureDialog>
         
    </div>
        
    )

}


export default ProfilePicture;