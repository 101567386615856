import React from "react";
import { Link } from "react-router-dom";
import style from './professional_certifications.module.css';
import Navbar from "../../../partials/navbar/navbar";
import SideBar from "../../../partials/sidebar/sidebar";
import ContentContainer from "../../../includes/content_container/contentContainer";
import MainContainer from "../../../includes/main_container/mainContainer";
import PageHeader from "../../../components/page_header/pageHeader";
import Footer from "../../../partials/footer/footer";

const ProfessionalCertificationsPage = () => {

    const certifications = [
        {
            download_url : 'xxxxxxxxxxxxxxx',
            title: "NCA Diploma Brick Layering",
            date_issued: '2009-04-11',
            issued_by: 'NCA',
        },
        {
            download_url : 'xxxxxxxxxxxxxxx',
            title: "National Diploma Brick Layering",
            date_issued: '2009-04-10',
            issued_by: 'BCA',
        }
    ] 


    const showCertifications = certifications.map((certification) => {
        return(
            <div className={style.certification_card}>
                    <div className={style.certification_details}>
                        <h3 className={style.certification_title}>{certification.title}</h3>
                        <small>{certification.date_issued}</small>
                    </div>
                    <div className={style.certification_actions}>
                        <button type='button' className={style.btn_action}><i className='bi bi-download'></i></button>
                        <button type='button' className={style.btn_action}><i className='bi bi-trash'></i></button>
                    </div>

            </div>
           
        ) 
    
    })

    return(
        <>
            <Navbar></Navbar>
            <ContentContainer>
                <SideBar></SideBar>
                <MainContainer>
                    <div className={style.flex_container}>
                        <h2>Your Credentials</h2>
                       <div className={style.flex_row}>
                            <h4>Manage Your Certificate Files here</h4>
                            <button type="button" className={style.btn_add_new}> <i className="bi bi-plus-lg"></i> Add New </button>
                       </div>
                       <div className={style.certification_container}>
                            { showCertifications }
                        </div>
                    </div>
                </MainContainer>
            </ContentContainer>
            <Footer />
            
        </>
    )

}

export default ProfessionalCertificationsPage;