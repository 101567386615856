import { BACKEND_URL } from '../config/api'
import { store } from '../store/store'



export const fetchHandymanProfileUsingUserId = async (userId="") =>{
    const response = await fetch(`${BACKEND_URL}/api/v1/handyman/get-handyman-by-user-id/?_id=${userId}`);
    const data = await response.json();

    if (!response.ok){
        throw new Error(data.message || "Something went wrong");
    }
    store.dispatch({ type: 'SET_HANDYMAN_PROFILE', payload: data })
    return data
    
}


/**
 * This function is used to remove a handyman's skill from the db. 
 * It is called when the user removes a skill from their profile. 
 * @param {*} handymanId 
 * @param {*} skillId 
 */
export const removeHandymanSkill = async (handymanId, skillId) =>{
    const requestUrl = `${BACKEND_URL}/api/v1/handyman/remove-handyman-skill/?_id=${handymanId}`;

    var reqData = {
        skill_id: skillId
    }

    const requestOptions = {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqData)
    } 

    const response = await fetch(requestUrl, requestOptions);

    const data = await response.json();
    
    if (!response.ok){
        
        throw new Error(data.message || "Something went wrong");
    }
    return data
}


/**
 * This function is used to update the selected handyman's skills in the db
 * @param {*} handymanId 
 * @param {*} skill 
 * @returns 
 */
export const addHandymanSkill = async (handymanId="", skill={}) => {

    const requestUrl = `${BACKEND_URL}/api/v1/handyman/update-skills/?_id=${handymanId}`;

    const requestOptions = {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(skill)
    } 

    const response = await fetch(requestUrl, requestOptions);

    const data = await response.json();
    
    if (!response.ok){
        
        throw new Error(data.message || "Something went wrong");
    }
    return data

       
} 

/**
 * Uploads an image to the handymans gallery.
 * @param {*} handymanId 
 * @param {*} formData
 * @returns
 */
export const uploadGalleryImage = async (handymanId,formData) => {
    const requestUrl = `${BACKEND_URL}/api/v1/handyman/upload-gallery-image/?handyman_id=${handymanId}`;

    const requestOptions = {
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        mode: "cors", // Change mode to "cors"
        cache: "no-cache",
        body: formData
    };

    try {
        const response = await fetch(requestUrl, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || "Something went wrong");
        }

        return data;
    } catch (error) {
        console.error("Error during image upload:", error);
        throw error; // Re-throw the error to be caught by the caller
    }
};

/**
 * This function is used to remove an image from the handyman's gallery of completed work.. 
 * @param {*} handymanId 
 * @param {*} imagePath 
 */
export const removeGalleryImage = async (handymanId, imagePath) =>{
    const requestUrl = `${BACKEND_URL}/api/v1/handyman/remove-gallery-image/?handyman_id=${handymanId}`;

    var reqData = {
        image_path: imagePath
    }

    const requestOptions = {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqData)
    } 

    const response = await fetch(requestUrl, requestOptions);

    const data = await response.json();
    
    if (!response.ok){
        
        throw new Error(data.message || "Something went wrong");
    }
    return data
}