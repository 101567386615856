import React from "react";
import style from './progress_bar.module.css'

const ProgressBar = ({ percentage }) => {

    return (
        <div className={style.container}>
            <div className={style.percentage}>{percentage}%</div>
            <div className={style.bar}>
                <div 
                className={style.progress}
                style={{width: `${percentage}%`}}  
                />
            </div>
        </div>
    )
    
}

export default ProgressBar;

