import React from "react";
import style from './input_helper_text.module.css'



export const InputSuccess = ({message= ''}) =>{
    /**
     * This component is used to display an error message when an input is valid
     */
    return(
        <span className={style.success}>{ message }</span>
    )
}


export const InputWarning = ({message= ''}) =>{
    /**
     * This component is used to display an error message when an input is invalid
     */
    return(
        <span className={style.warning}>{ message }</span>
    )
}

export const InputError = ({message= ''}) =>{
    /**
     * This component is used to display an error message when an input is has an error
     */
    return(
        <span className={style.danger}>{ message }</span>
    )
}