import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import style from './date_picker.module.css';

function DateInput({ label, name, selectedDate, onDateChange, ...otherProps }) {
  return (
    <div className={style.date_input}>
      <label htmlFor={name}>{label}</label>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => onDateChange(date)}
        dateFormat="yyyy-MM-dd" // Customize date format here (optional)
        {...otherProps}
      />
    </div>
  );
}

export default DateInput;