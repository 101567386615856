import React from 'react';
import style from './projects.module.css'
import Navbar from '../../partials/navbar/navbar';
import SideBar from '../../partials/sidebar/sidebar';
import { Link } from 'react-router-dom';

/**
 * This is a Project component, it shows completed and scheduled work for the handyman
 *
 */
const Projects = () => { 

    const [currentTab, setCurrentTab] = React.useState(0);
    
    const handleTabClick = (index) => {
        setCurrentTab(index);
    };

    const tabHeading = ["Upcoming Projects", "Inprogress Projects", "Completed Projects"];
    const showTabs = tabHeading.map((heading, index) => {
        return(
            <div className={style.tab_heading + ' ' + (index === currentTab ? style.active_tab : ' ')} key={index} onClick={() => handleTabClick(index)}>
                <h5>{heading}</h5> 
            </div>
        )
    });

    const upcomingProjects = [
        {
            project_id: 1,
            category: "Plumbing",
            description: "Install a new toilet in the kitchen",
            booking_date: "2023-04-20",
            booking_time: "10:00",
            location: "123 Main St, Anytown, USA",
            status: "Upcoming",
            client_details : {
                profile_image: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                names: "John Doe",
                email: "john@example.com",
                cellphone: "555-1234"
            },
            qoutation_amount: "100.00",
            notes: "",
            client_rating: 0,
            images: []
            
        },
        {
            project_id: 4,
            category: "Plumbing",
            description: "Replace house tiles",
            booking_date: "2023-04-25",
            booking_time: "8:00",
            location: "123 Main St, Anytown, USA",
            status: "Upcoming",
            client_details : {
                profile_image: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                names: "John Doe",
                email: "john@example.com",
                cellphone: "555-1234"
            },
            qoutation_amount: "500.00",
            notes: "",
            client_rating: 0,
            images: []
            
        }
    ] 

    const inProgressProjects =[
        {
            project_id: 2,
            category: "Home Electrician",
            description: "Replace Outside lights",
            booking_date: "2023-05-11",
            booking_time: "9:00",
            location: "123 Main St, Anytown, USA",
            status: "in-progress",
            client_details : {
                profile_image: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                names: "Sally Doe",
                email: "john@example.com",
                cellphone: "555-1234"
            },
            qoutation_amount: "80.00",
            notes: "",
            client_rating: 0,
            images: []
            
        }

    ]

     const completedProjects = []

    const showUpcomingProjects = upcomingProjects.map((project, index) => {
        return(
            <div className={style.project_card} key={index}>
                <div className={style.project_info}>
                    <div className={style.project_category}>{project.category}  <small className={style.project_upcoming}>{project.status}</small></div>
                    <div className={style.project_location}>{project.location}</div>
                </div>
                <div className={style.project_details}>
                    <div className={style.project_booking_date}><i className='bi bi-calendar'></i> {project.booking_date}</div>
                    <div className={style.project_booking_time}><i className='bi bi-clock'></i> {project.booking_time}</div>
                </div>
                <div className={style.project_actions}>
                    <Link to={'#'} className={style.btn_go_to_project}>Go To Project</Link>
                </div>
                
            </div>
        )

    })


    const showInProgressProjects = inProgressProjects.map((project, index) => {
        return(
            <div className={style.project_card} key={index}>
                <div className={style.project_info}>
                    <div className={style.project_category}>{project.category}</div>
                    <div className={style.project_description}>{project.description}</div>
                    <div className={style.project_location}>{project.location}</div>
                </div>
                <div className={style.project_details}>
                    <div className={style.project_booking_date}>{project.booking_date}</div>
                    <div className={style.project_booking_time}>{project.booking_time}</div>
                    <div className={style.project_status}>{project.status}</div>
                </div>
            </div>
        )
    })

    const showCompletedProjects = completedProjects.map((project, index) => {
        return(
            <div className={style.project_card} key={index}>
                <div className={style.project_info}>
                    <div className={style.project_category}>{project.category}</div>
                    <div className={style.project_description}>{project.description}</div>
                    <div className={style.project_location}>{project.location}</div>
                </div>
                <div className={style.project_details}>
                    <div className={style.project_booking_date}>{project.booking_date}</div>
                    <div className={style.project_booking_time}>{project.booking_time}</div>
                    <div className={style.project_status}>{project.status}</div>
                </div>
            </div>
        )
    })
    const toggleBetweenTabs = () => {
        switch(currentTab){
            case 0:
                 if (upcomingProjects.length === 0){
                    return <span>No Upcoming Projects</span>
                 }
                return showUpcomingProjects;
            case 1:
                if (inProgressProjects.length === 0){
                    return <span>No Inprogress Projects</span>
                }
                return showInProgressProjects;
            case 2:
                if (completedProjects.length === 0){
                    return <span>No Completed Projects</span>
                }
                return showCompletedProjects;
            default:
                return null;
        }
        
    
    }


    return(
        <div>
            <Navbar></Navbar>
            <div className="content">
                <SideBar></SideBar>
                <main className={style.wrapper}>
                    <div className={style.flex_container}>
                        <div className={style.tabs}>
                            {showTabs}
                        </div>
                        <div className={style.tab_content}>
                           { toggleBetweenTabs() }
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
    

}

export default Projects;