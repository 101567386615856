import { AUTH_URL } from '../config/api';
import { store } from '../store/store';
import { decodeJsonToken } from "../utils/jwt_utils";

/**
 * Authenticates users using cellphone and password
 * @returns 
 */


export const login = async (authData= {}) => {

    const requestUrl = `${AUTH_URL}/api/v1/auth/login`

    const requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(authData)
    }
    
    const response = await fetch(requestUrl, requestOptions);

    const data = await response.json();

    if(!response.ok) {
        throw new Error(data.message || 'Something went wrong')
    } 
    let token = data._token;
    
    store.dispatch({ type: 'LOGIN', payload: token })

    let decodedToken = decodeJsonToken(token)

    store.dispatch({ type: 'SET_USER_ID', payload: decodedToken.user_id })

    return token
}