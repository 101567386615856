import React, { useState,useEffect } from "react"
import "react-datepicker/dist/react-datepicker.css";
import PageHeader from "../../../components/page_header/pageHeader";
import { LoaderDotted } from "../../../components/input_helper/loader/loader";
import Form from "../../../components/form/form";
import TextInput from "../../../components/text-input/text-input";
import DateInput from "../../../components/date_picker/date_picker";
import FileDragDrop from "../../../components/file_drag_drop/file_drag_drop";
import { DefaultButtonOutline } from "../../../components/buttons/button";
import { useSelector } from "react-redux";
import { getVerificationInformation, uploadIdentityDocument } from "../../../services/account_verification_service"
import toast, { Toaster } from 'react-hot-toast';
import VerificationComplete from "./verification_complete"
import PendingVerification from "./pending_verification";


/**
 * This component is used to conduct Background checks as an optional step for added security and user trust 
 * @returns  {JSX.Element}
 */
const AccountVerification = () =>{

    const userId = useSelector(state => state.auth.userId);
    const [verificationStatus, setVerificationStatus] = useState("rejected")
    const [fullname,setFullname] = useState('');
    const [indentityNumber,setIdentityNumber]  = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [document, setDocument] = useState(null);
    const [expiryDate, setExpiryDate] = useState(new Date());

    const toastError = (message="") => {
        return toast.error(message ? message : "Oops! An error occurred.")
    }

    useEffect(() =>{
        getVerificationInformation(userId)
            .then((res) =>{
                if(res !== null){
                    if(res.status === "approved"){
                        setVerificationStatus("approved")
                    }else{
                        setVerificationStatus("pending")
                    }  
                }
               
            })
            .catch((error) =>{
                console.error(error)
                toastError()
            })
    },[userId])

    const handleSubmit = () =>{
        setIsLoading(true)
        const formData = new FormData();
        formData.append('fullname', fullname)
        formData.append('identity_number', indentityNumber)
        formData.append('document_expiry_date', expiryDate)
        formData.append('document', document)

        uploadIdentityDocument(userId,formData)
            .then((response) =>{
               if(response.data.is_uploaded){
                setVerificationStatus("pending")
                toast('Saved, Our team is now checking your information!', {
                    icon: '👏',
                  });
                setIsLoading(false)
               }
               
            })
            .catch((error) =>{
                console.error(error)
                toastError()
                setIsLoading(false)
            })

    }

    const switchVerificationComponents = () =>{
       switch (verificationStatus) {
           case "pending":
               return <PendingVerification />
           case "approved":
               return <VerificationComplete />
           default:
               return<>
                    <PageHeader title={"Verify Your Identity"} 
                        tagline={"Upload your passport or national ID to verify your account.."} />
                          <Form onSubmit={handleSubmit}> 
                        <TextInput 
                            label="Full names as shown on identity document" 
                            name="fullname"
                            value={fullname}
                            onChange={setFullname}
                            />
                        <TextInput 
                            label="National ID/Passport Number" 
                            name="identity_number"
                            value={indentityNumber}
                            onChange={setIdentityNumber} 
                            />
                        <DateInput label={"Document Expiry Date"} 
                            name={"expiry_date"} 
                            selectedDate={expiryDate} 
                            onDateChange={setExpiryDate}/>
                        
                        <FileDragDrop FileChange={setDocument} FileDrop={setDocument} />
                        {isLoading ? <LoaderDotted /> : <DefaultButtonOutline>Verify Identity</DefaultButtonOutline>}
                    </Form>
               </>
              
       }
    }
  
    return(
        <>           
            {switchVerificationComponents()} 
            <Toaster
                position="top-center"
                reverseOrder={true}
            />
        </>
    )
}

export default AccountVerification;