import React from "react";
import style from './page_header.module.css'

const PageHeader = ({ title, tagline,children }) => {
    return(
        <div className={style.page_header}>
            <h2>{title}</h2>
            <p>{tagline}</p>
            <div>{children}</div>
        </div>

    )
}

export default PageHeader;